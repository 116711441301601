/* @flow */

import type { SortableProductList } from "shop-state/types";
import type { History } from "react-router";

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { withRouter } from "react-router";
import { useData, useSendMessage } from "crustate/react";
import { SearchData, CustomerData } from "data";
import Wrapper from "components/Wrapper";
import ProductList from "components/ProductList";
import { useTranslate } from "@awardit/react-use-translate";
import { OffCanvasFilterMenu, useFilter, ActiveFilters } from "@crossroads/ui-components";
import { Title } from "components/UiComponents";
import PaginatedProductList from "components/CategoryView/paginated-product-list";
import Filterbar from "components/Filterbar";
import { parseParams } from "helpers/location-search-string";
import { search } from "@crossroads/shop-state/search";
import { useOpenFiltermenu } from "helpers/use-open-filtermenu";
import { constructSortableValues } from "helpers/filters";

import styles from "./styles.scss";

import { PAGE_SIZE } from "effects/route";

type SearchProps = {
  data: SortableProductList,
  query: string,
};

type HintSearchProps = {
  query: string,
};

type WrapperProps = {
  history: History,
};

const SearchViewWrapper = ({ history }: WrapperProps) => {
  const search = useData(SearchData);
  const query = history.location.pathname.split("/").slice(-1).pop();

  useEffect(() => {
    if (search.state === "LOADED" && search.data.redirect && search.data.redirect !== null) {
      const redirectPath = new URL(search.data.redirect).pathname;

      history.replace(redirectPath);
    }
  }, [search.state]);

  if (search.state !== "LOADED" && search.state !== "UPDATING") {
    return <HintSearchView query={query} />;
  }

  if (search.state === "LOADED" || search.state === "UPDATING") {
    const { filterableBy, items, sortableBy, totalCount } = search.data;

    return (
      <SearchView
        data={{
          filterableBy,
          items,
          sortableBy,
          totalCount,
        }}
        query={query}
        history={history}
      />
    );
  }

  return null;
};

const SearchView = ({ data, query }: SearchProps) => {
  const t = useTranslate();
  const location = useLocation();
  const sendMessage = useSendMessage();
  const searchData = useData(SearchData);
  const updating = searchData.state === "UPDATING";
  const openFiltermenu = useOpenFiltermenu();
  const customer = useData(CustomerData);
  const memberTargetList = customer.state === "LOGGED_IN" &&
  customer.data &&
  customer.data.memberTargetList &&
  customer.data.memberTargetList.list.length > 0 ?
    customer.data.memberTargetList.list :
    null;

  const useFilterConfig = {
    loading: updating,
    productList: data,
    usePoints: true,
    incVat: false,
    load: location => {
      sendMessage(search(query, location, false));
    },
  };

  const nonLinearSlider = true;
  const filterState = useFilter(useFilterConfig);
  const params = parseParams(location.search);
  const page = Math.max(1, parseInt(params.page, 10) || 1);
  const numPages = Math.ceil(data.totalCount / PAGE_SIZE);

  const numOfProducts = memberTargetList && data.totalCount > 0 ?
    data.items.filter(p =>
      memberTargetList.includes(p.attributes.awarditTargetId) ||
      !p.attributes.awarditTargetId).length :
    null;

  return (
    <Wrapper className={styles.searchView}>
      <Helmet
        title={`${t("SEARCH.TITLE_PREFIX")}${query}`}
      />

      <Title>
        <span className={styles.titlePrefix}>

          {numOfProducts != null && numOfProducts > 1 &&
            t("SEARCH.PRODUCTS_IN_SEARCH_RESULT", { itemsCount: numOfProducts })
          }

          {numOfProducts != null && numOfProducts === 1 &&
            t("SEARCH.PRODUCT_IN_SEARCH_RESULT", { itemsCount: numOfProducts })
          }

          {!memberTargetList && data.totalCount > 1 &&
            t("SEARCH.PRODUCTS_IN_SEARCH_RESULT", { itemsCount: data.totalCount })
          }

          {!memberTargetList && data.totalCount === 1 &&
            t("SEARCH.PRODUCT_IN_SEARCH_RESULT")
          }

          {!memberTargetList && data.totalCount === 0 &&
            t("SEARCH.EMPTY_SEARCH_RESULT")
          }

          {memberTargetList && data.totalCount === 0 &&
            t("SEARCH.EMPTY_SEARCH_RESULT")
          }

        </span> &quot;{query}&quot;

        {filterState.active.filters.length > 0 &&
          <>
            &nbsp;{t("SEARCH.AND_ACTIVE_FILTERS")}
          </>
        }
      </Title>

      <div className={styles.activeFiltersRow}>
        {filterState.active.filters.length > 0 &&
          <h2 className={styles.activeFiltersRowHeader}>
            {t("FILTER.ACTIVE_FILTERS.COUNT")}
            <span className={styles.activeFiltersRowCount}>{` (${filterState.active.filters.length})`}</span>
          </h2>
        }
        <ActiveFilters
          hideEmptyFilters
          filterState={filterState}
          className={styles.activeFilters}
        />
      </div>

      <Filterbar
        openFilters={openFiltermenu.openFiltermenu}
        className={styles.filterbar}
        hasDefaultSort={false}
        filterState={{
          ...filterState,
          sort: {
            ...filterState.sort,
            values: Array.isArray(filterState.sort.values) ?
              constructSortableValues(filterState.sort.values, t) :
              [],
          },
        }} />

      <PaginatedProductList
        updating={updating}
        page={page}
        productList={data}
        numPages={numPages}
        breadcrumbLink={{ search: query }}
        loading={updating}
        category={`${t("SEARCH.TITLE_PREFIX")}${query}`}
      />

      <OffCanvasFilterMenu
        close={openFiltermenu.closeFiltermenu}
        isOpen={openFiltermenu.isOpen}
        filterState={filterState}
        nonLinearSlider={nonLinearSlider}
        exponentiation={4}
        containerClass="awardit-OffCanvasFilterMenu"
      />
    </Wrapper>
  );
};

export const HintSearchView = ({ query }: HintSearchProps) => {
  const t = useTranslate();

  return (
    <Wrapper>
      <Helmet
        title={`${t("SEARCH.TITLE_PREFIX")}${query}`}
      />
      <Title>{t("SEARCH.SEARCHING_FOR")} &quot;{query}&quot;</Title>

      <ProductList products={[null, null, null, null, null, null, null, null]} />
    </Wrapper>
  );
};

export default withRouter(SearchViewWrapper);
