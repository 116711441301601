/* @flow */

import * as React from "react";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  className?: string,
  children: React.Node,
};

export const Ingress = ({ children, className = "", ...props }: Props) => {
  return (
    <div {...props} className={cn(styles.ingress, className)}>
      {children}
    </div>
  );
};
