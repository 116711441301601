/* @flow */

import React from "react";
import cn from "classnames";

import styles from "./styles.scss";

type Item = {
  company: string,
  name: string,
  points: number,
  position: number,
};

type Props = {
  leaderboardRows: Array<Item>,
  currentLeaderboardUser?: Item | null,
};

const HeroLeaderboardTable = ({ leaderboardRows, currentLeaderboardUser }: Props): any => {
  return (
    <div>
      {leaderboardRows &&
      leaderboardRows.length > 0 &&
      <div
        className={styles.HeroLeaderboardTable}
      >
        {leaderboardRows.map((item, i) => (
          <div
            key={`${item.name}-${i}`}
            className={cn(styles.tableItem, { [styles.currentUser]:
            currentLeaderboardUser && currentLeaderboardUser.name === item.name })}
          >
            <p className={styles.itemIndex}>{item.position}</p>
            <p>{item.name}</p>
            <p className={styles.itemPoints}>{item.points}</p>
          </div>
        ))}
      </div>
      }
    </div>
  );
};

export default HeroLeaderboardTable;
