/* @flow */

import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { Toggleable } from "@crossroads/ui-components";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";

const CLOSED_SUFFIX = "/f/closed";
const ANSWERED_SUFFIX = "/f/answered";

const Questionnaires = () => {
  const { location: { pathname } } = useHistory();
  const { routes, content: { questionnairelistview } } = useContext(StoreInfoContext);
  const questionaireUrl = routes.questionnaireView && routes.questionnaireView.url;

  if (!questionaireUrl) {
    return null;
  }

  return (
    <>
      <Link
        className={styles.link}
        to={questionaireUrl}
      >
        {questionnairelistview.open &&
          <Toggleable active={pathname === questionaireUrl}>
            {questionnairelistview.open}
          </Toggleable>
        }
      </Link>
      <Link
        className={styles.link}
        to={questionaireUrl + CLOSED_SUFFIX}
      >
        {questionnairelistview.closed &&
          <Toggleable active={pathname === questionaireUrl + CLOSED_SUFFIX}>
            {questionnairelistview.closed}
          </Toggleable>
        }
      </Link>
      <Link
        className={styles.link}
        to={questionaireUrl + ANSWERED_SUFFIX}
      >
        {questionnairelistview.answered &&
          <Toggleable active={pathname === questionaireUrl + ANSWERED_SUFFIX}>
            {questionnairelistview.answered}
          </Toggleable>
        }
      </Link>
    </>
  );
};

export default Questionnaires;
