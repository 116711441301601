/* @flow */

import React from "react";
import cn from "classnames";

import styles from "./styles.scss";

type CurrentInfoCardProps = {
  idx: number,
  info: {
    +title: ?string,
    +content: ?string,
    +image: ?string,
  },
  className?: string,
};

/* eslint-disable react/no-danger */

const LayoutA = ({ className, title, image, content }: {
  className?: string, title: string, image: string, content: string,
}
) => (
  <div className={cn(styles.banner, styles.a, className)}>
    <div>
      <img src={image} />
    </div>
    <div>
      <h2>{title}</h2>
      <p dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  </div>
);

const LayoutB = ({ className, image }: {
  className?: string, image: string,
}
) => (
  <div className={cn(styles.banner, styles.b, className)}>
    <img src={image} />
  </div>
);

const LayoutC = ({ className, title, content }: {
  className?: string, title: string, content: string,
}
) => (
  <div className={cn(styles.banner, styles.c, className)}>
    <h2>{title}</h2>
    <p dangerouslySetInnerHTML={{ __html: content }} />
  </div>
);

const InfoCard = ({ info, idx, className = "" }: CurrentInfoCardProps) => {
  if (info.content && info.image && info.title) {
    return (
      <LayoutA
        key={String(info.title) + idx}
        content={info.content || ""}
        image={info.image || ""}
        title={info.title || ""}
        className={className}
      />
    );
  }

  if (info.content && info.title) {
    return (
      <LayoutC
        key={String(info.title) + idx}
        content={info.content || ""}
        title={info.title || ""}
        className={className}
      />
    );
  }

  if (info.image) {
    return (
      <LayoutB
        key={String(info.title) + idx}
        image={info.image || ""}
        className={className}
      />
    );
  }

  return null;
};

/* eslint-enable react/no-danger */

export default InfoCard;
