/* @flow */

import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.scss";

type Supplier = {
  img: string,
  title: string,
  url: string,
};

type Props = {
  logos?: Array<Supplier>,
};

const LogoList = ({ logos = [] }: Props) => {
  return (
    logos.length > 0 && (
      <div className={styles.logos}>
        {logos.map(x => (
          <div key={x.title} className={styles.logo}>
            <Link to={x.url}><img src={x.img} alt={x.title} /></Link>
          </div>
        ))}
      </div>
    )
  );
};

export default LogoList;
