/* @flow */

import type { CurrentInfoListItem, CurrentInfoRoute } from "shop-state/types";

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { Helmet } from "react-helmet-async";
import { useData } from "crustate/react";
import { CurrentInfoListData } from "data";
import Breadcrumbs from "components/Breadcrumbs";
import ErrorView from "components/ErrorView";
import Wrapper from "components/Wrapper";
import cn from "classnames";
import { stutter } from "helpers/animation";
import styles from "./styles.scss";
import { Title } from "components/UiComponents";

import { CurrentInfoDummyList } from "components/CurrentInfo/CurrentInfoListView/List";

type Props = {
  route: CurrentInfoRoute,
  list: $ReadOnlyArray<CurrentInfoListItem>,
};

type DummyProps = {
  route: CurrentInfoRoute,
};

type WrapperProps = {
  route: CurrentInfoRoute,
};

const CurrentInfoContent = ({ route, list }: Props) => {
  const { content } = useContext(StoreInfoContext);
  const _stutter = stutter(100, 100);

  return (
    <>
      <Helmet title={route.title} />
      <Wrapper className={cn("awardit-wrapper", `awardit-${route.name}`)}>
        <Breadcrumbs current={route.title || ""} />
        <Title>{route.title}</Title>
        {/* eslint-disable react/no-danger */}
        {content[route.name] &&
        content[route.name].introDescription &&
          <div
            dangerouslySetInnerHTML={{ __html: content[route.name].introDescription }}
            style={{ marginBottom: "4rem", maxWidth: "100ch" }}
          />
        }
        {/* eslint-enable react/no-danger */}
        {list.map((x, i) => (
          <div
            key={x.title}
            id={`currentInfoContent-${x.id ? x.id : ""}`}
            className={styles.currentInfoContent}
            style={{
              animationDelay: _stutter(i),
            }}
          >
            <h2>{x.title}</h2>
            {/* eslint-disable react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: x.content }} />
            {/* eslint-enable react/no-danger */}
          </div>
        ))}
      </Wrapper>
    </>
  );
};

const CurrentInfoListDummyView = ({ route }: DummyProps) => {
  return (
    <>
      <Helmet title={route.title} />
      <Wrapper className={cn("awardit-wrapper", `awardit-${route.name}`)}>
        <Breadcrumbs current={route.title || ""} />
        <Title>{route.title}</Title>
        <CurrentInfoDummyList path={route.url} />
      </Wrapper>
    </>
  );
};

const CurrentInfoContentWrapper = ({ route }: WrapperProps) => {
  const data = useData(CurrentInfoListData);

  if (data.state === "LOADING") {
    return <CurrentInfoListDummyView route={route} />;
  }

  if (data.state === "ERROR") {
    return <ErrorView />;
  }

  return <CurrentInfoContent route={route} list={data.data} />;
};

export default CurrentInfoContentWrapper;
