/* @flow */

export const suppliers = { "5416": [
  { img: "/assets/suppliers/5416/glen_dimplex_nordic.jpeg", title: "Glen Dimplex logo", url: "http://www.glendimplex.se/" },
  { img: "/assets/suppliers/5416/sg.jpeg", title: "SG", url: "http://www.sg-as.no/swe/" },
  { img: "/assets/suppliers/5416/signify.jpeg", title: "Signify", url: "http://www.lighting.philips.se/" },
  { img: "/assets/suppliers/5416/rutab.jpeg", title: "Rutab", url: "http://www.rutab.se/" },
  { img: "/assets/suppliers/5416/obo_bettermann.jpeg", title: "Obo Betterman", url: "http://www.obobettermann.se/" },
  { img: "/assets/suppliers/5416/nvent_raychem.jpeg", title: "T2", url: "http://www.t2.se/" },
  { img: "/assets/suppliers/5416/mp_bolagen.jpeg", title: "MPBolagen", url: "http://www.mpbolagen.se/" },
  { img: "/assets/suppliers/5416/pm_flex.jpeg", title: "PM Flex", url: "http://www.pmflex.se/" },
  { img: "/assets/suppliers/5416/elko.jpeg", title: "Elko", url: "http://www.elko.se/" },
  { img: "/assets/suppliers/5416/hidealite.jpeg", title: "Hidealite", url: "http://www.hidealite.se/" },
  { img: "/assets/suppliers/5416/hager.jpeg", title: "Hager", url: "http://www.hager.se/" },
  { img: "/assets/suppliers/5416/garo.jpeg", title: "Garo", url: "http://www.garo.se/" },
  { img: "/assets/suppliers/5416/ensto.jpeg", title: "Ensto", url: "http://www.ensto.se/" },
  { img: "/assets/suppliers/5416/abb.jpeg", title: "ABB", url: "http://new.abb.com/se" },
  { img: "/assets/suppliers/5416/thorn.jpeg", title: "Thorn", url: "http://www.thornlighting.se/" },
  { img: "/assets/suppliers/5416/schneider_electric.jpeg", title: "Schneider Electric", url: "http://www.schneiderelectric.se/" },
  { img: "/assets/suppliers/5416/nkt.jpeg", title: "NKT", url: "http://www.nkt.se/" },
  { img: "/assets/suppliers/5416/draka.jpeg", title: "Draka", url: "http://www.draka.se/" },
  { img: "/assets/suppliers/5416/luzense_unilamp.jpeg", title: "Unilamp", url: "https://unilamp.se/" },
  { img: "/assets/suppliers/5416/ledvance.jpeg", title: "Ledvance", url: "http://www.ledvance.se/" },
  { img: "/assets/suppliers/5416/westal.jpeg", title: "Westal", url: "http://www.westal.se/" },
] };
