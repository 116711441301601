/* @flow */

import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import CartIcon from "icons/cart.svg";

import styles from "./styles.scss";

type Props = {
  className: string,
  location: { pathname: string },
  isOpen: boolean,
  openMiniCart: () => void,
  children: React$Node,
  history: {
    push: (path: string) => void,
    goBack: () => void,
  },
};

const CartCounter = ({ className = "", isOpen, openMiniCart, children }: Props) => {
  const { push } = useHistory();
  const { routes } = useContext(StoreInfoContext);
  const data = useData(QuoteData);
  const quote = data.state === "LOADED" || data.state === "UPDATING" ? data.data : {};
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    if (data.state !== "LOADED") {
      return;
    }

    setCartCount(quote.items.reduce((a, { qty }) => {
      a += qty;
      return a;
    }, 0));
  }, [data, quote]);

  if (cartCount > 0) {
    return (
      <span
        className={cn(
          styles.block,
          { [styles.active]: isOpen },
          className
        )}
        onClick={() => {
          if (cartCount) {
            openMiniCart();
          }
          else {
            push(`${(routes.checkoutView && routes.checkoutView.url) ?? ""}/cart`);
          }
        }}
      >
        <div className={styles.iconWrapper}>
          <CartIcon />
        </div>

        <span className={styles.cartCount}>{cartCount}</span>

        {children}
      </span>
    );
  }

  return (
    <div className={cn(styles.block, className)}>
      <div className={styles.iconWrapper}>
        <CartIcon />
      </div>
    </div>
  );
};

export default withRouter(CartCounter);
