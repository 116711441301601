/* @flow */

export type NewsPost = {
    title?: ?string,
    fromDate?: ?string,
    content?: ?string,
    image?: ?string,
    link?: ?string,
    id?: ?string,
};

import React, { useContext } from "react";
import { HashLink } from "react-router-hash-link";
import { AnalyticsContext } from "@crossroads/analytics";

import styles from "./styles.scss";

const NewsListItem = ({ item: {
  title, fromDate, image, link, id } }: { item: NewsPost }) => {
  const gaContext = useContext(AnalyticsContext);
  const gaRegister = () => {
    if (id === null || id === undefined || !title) {
      return null;
    }

    gaContext.registerContentClick(`News: ${title}`, id.toString());
  };

  return (
    <HashLink
      smooth
      className={styles.block}
      to={link}
      onClick={gaRegister}
    >
      <div className={styles.shadow} />
      {image ? <img className={styles.image} src={image} /> : null }
      <div className={styles.contentWrapper}>
        <header className={styles.top}>
          <span className={styles.date}>{fromDate}</span>
        </header>
        <h2 className={styles.title}>{title}</h2>
      </div>
    </HashLink>
  );
};

export const DummyNewsListItem = () => (
  <div className={styles.dummy_block}>
    <div className={styles.dummy_top}>
      <div className={styles.dummy_date} />
    </div>
    <div className={styles.dummy_title} />
  </div>
);

export default NewsListItem;
