/* @flow */

import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useSendMessage, useData } from "crustate/react";
import { StoreInfoContext } from "entrypoint/shared";
import { MemberGroupIsOwnerData } from "data";
import Button from "components/Button";
import Breadcrumbs from "components/Breadcrumbs";
import { logout } from "@crossroads/shop-state/customer";
import Wrapper from "components/Wrapper";
import RecruitAFriend from "components/RecruitAFriend";
import { BtnComponent } from "components/MemberGroupView";
import UserDetails from "components/AccountView/user-details";
import Password from "components/AccountView/password";
import YourPoints from "components/AccountView/your-points";
import LastOrders from "components/AccountView/last-orders";
import NewsletterSubscription from "components/AccountView/newsletter-subscription";
import AdditionalMemberNumbers from "components/AccountView/additional-member-numbers";
import AdminButton from "components/AccountView/AdminButton";
import ChevronIcon from "icons/chevron-mini.svg";
import { Title } from "components/UiComponents";
import cn from "classnames";

import styles from "./styles.scss";

const AccountView = ({ customer }: { customer: Customer }) => {
  const sendMessage = useSendMessage();
  const userMemberGroupData = useData(MemberGroupIsOwnerData);
  const {
    content, content: { accountview, tinkview },
    routes, routes: { accountView, accountTinkView, memberGroupView },
  } = useContext(StoreInfoContext);

  const showAdminButton = Boolean(accountview.showAdministration) &&
    ((customer.awardit.userType || 0) > 0);

  return (
    <Wrapper className={cn(styles.accountView, "awardit-AccountView")}>
      <Helmet
        title={accountview.pageTitle}
      />
      <div className={cn(styles.header, "awardit-AccountViewHeader")}>
        <div>
          <Breadcrumbs current={accountview.title ?? ""} />
          {accountview.title &&
            <Title className={styles.title}>{accountview.title}</Title>
          }
        </div>
        {accountview.logout &&
          <Button className={cn(styles.logout, "awardit-accountViewLogoutButton")} variant="link" onClick={() => sendMessage(logout())}>
            {accountview.logout ?? ""}
          </Button>
        }
      </div>

      {(userMemberGroupData.state === "LOADED" && userMemberGroupData.data.owner) && (
        memberGroupView && memberGroupView.toggle !== undefined && memberGroupView.toggle &&
        typeof memberGroupView.url === "string" && memberGroupView.url.length > 0) &&
        accountView && accountView.url && (
        <BtnComponent
          basePath={`${accountView.url}${memberGroupView.url}`}
          page={accountView.url}
          routes={routes}
          content={content}
        />
      )}

      <div className={styles.container}>
        <main className={styles.left}>
          <div className={styles.row}>
            <div className={styles.box}>
              <UserDetails customer={customer} />
            </div>
          </div>
          {accountview.showRecruitAFriend !== null &&
          accountview.showRecruitAFriend !== undefined &&
          accountview.showRecruitAFriend === true &&
            <div className={styles.row}>
              <div className={styles.box}>
                <RecruitAFriend />
              </div>
            </div>
          }
          {accountview.showPassword !== null &&
          accountview.showPassword !== undefined &&
          accountview.showPassword === true && (
            <div className={styles.row}>
              <div className={styles.box}>
                <Password />
              </div>
            </div>
          )}
        </main>
        <aside className={styles.right}>
          <div className={styles.row}>
            <div className={styles.box}>
              <YourPoints customer={customer} />
            </div>
          </div>
          {accountview.showAdditionalMemberNumbers !== null &&
          accountview.showAdditionalMemberNumbers !== undefined &&
          accountview.showAdditionalMemberNumbers === true &&
            <div className={styles.row}>
              <div className={styles.box}>
                <AdditionalMemberNumbers />
              </div>
            </div>
          }
          <div className={styles.row}>
            <div className={styles.box}>
              <LastOrders />
            </div>
          </div>
        </aside>
      </div>

      <div className={styles.container}>
        <div className={styles.left}>
          {accountview.showMail !== null &&
          accountview.showMail !== undefined &&
          accountview.showMail === true &&
            <div className={styles.row}>
              <div className={styles.box}>
                <NewsletterSubscription customer={customer} />
              </div>
            </div>
          }
        </div>
        <div className={styles.right}>
          {(accountTinkView && accountTinkView.toggle !== undefined && accountTinkView.toggle) &&
            <div className={styles.row}>
              <div className={styles.box}>
                <header className={styles.boxHeader}>
                  {tinkview.heading &&
                    <h2 className={styles.boxHeading}>{tinkview.heading}</h2>
                  }
                  <Button to={accountTinkView.url} type="button">
                    {accountview.openTinkViewButtonCta &&
                      <span>{accountview.openTinkViewButtonCta}</span>
                    }
                    <ChevronIcon />
                  </Button>
                </header>
              </div>
            </div>
          }
        </div>
      </div>
      {showAdminButton &&
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.row}>
            {accountview.administration &&
              <AdminButton text={accountview.administration} />
            }
          </div>
        </div>
      </div>
      }
    </Wrapper>
  );
};

export default AccountView;
